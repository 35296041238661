<template>
  <div class="group-table">
    <div class="group-search">
      <a-form layout="inline"
              :model="searchForm">
        <a-form-item label="客户群名称">
          <a-input v-model:value="searchForm.name"
                   placeholder="请输入群名称"
                   allow-clear
                   @change="debounceSearch" />
        </a-form-item>
        <a-form-item label="建群时间">
          <a-range-picker v-model:value="timeValue"
                          format="YYYY-MM-DD"
                          @change="handleSetectTime"
                          style="width: 250px" />
        </a-form-item>

      </a-form>
    </div>

    <div class="sync-button">
      <a-button class="mr8"
                type="link"
                @click="handleSyncButton"
                :disabled="isSync">
        <SyncOutlined :spin="isSync" />同步
      </a-button>
      <span v-if="!syncTime"
            class="content">未同步</span>
      <span v-else
            class="content mr8">最后同步时间:{{syncTime.slice(0,16)}}</span>

      <span class="content">共{{pagination.total || 0}}个群</span>
    </div>

    <a-table :columns="columns"
             :data-source="group"
             :scroll="{ x: '100%', y: 'calc(100vh - 330px)' }"
             :pagination="pagination"
             row-key="workWechatChatId"
             @change="onPaginationChange"
             :loading="tableLoading">
      <template #ownerWorkWechatThirdAppUserId="{ text }">
        <div v-is="'ww-open-data'"
             class="ww-open-data"
             type="userName"
             :openid="text"></div>
      </template>

      <template #createdTime="{ record }">
        {{ $f.datetime(record.createdTime) }}
      </template>

      <template #action="{ record }">
        <router-link :to="{path:'groupDetail',query:{ id: record.id}}">详情
        </router-link>
      </template>
    </a-table>
  </div>
</template>

<script>
import _ from "lodash";
import groupChatApi from "@/service/api/groupChat";
import dayjs from "dayjs";
import { defineComponent, reactive, ref } from "vue";
import { message, DatePicker } from "ant-design-vue";
import usePaginationQuery from "@/shared/composables/usePaginationQuery";
import router from "@/router";
import { getWxAgentConfig } from "@/global";

export default defineComponent({
  name: "groupChat",

  components: { ARangePicker: DatePicker.RangePicker },

  setup() {
    const searchForm = reactive({
      name: "",
      startTime: "",
      endTime: "",
    });

    const timeValue = ref([]);
    const handleSetectTime = (res) => {
      if (res.length > 0) {
        searchForm.startTime = dayjs(res[0]._d).startOf("date").valueOf();
        searchForm.endTime = dayjs(res[1]._d).endOf("date").valueOf();
      } else {
        searchForm.startTime = "";
        searchForm.endTime = "";
      }
      onSearchSubmit();
    };

    const isSync = ref(false);
    const syncTime = ref("");
    const timer = ref(null);
    async function initSyncGroup() {
      const lastSyncTimeObj = await groupChatApi.getLastSyncTime();

      syncTime.value = lastSyncTimeObj.lastSyncTime;
      if (lastSyncTimeObj.processStatus !== "finished") {
        isSync.value = true;
        setInter();
      }
    }

    async function handleSyncButton() {
      isSync.value = true;
      await groupChatApi.asyncAllGroupChatList();
      setInter();
    }

    function setInter() {
      let i = 0;
      timer.value = setInterval(async () => {
        i++;
        if (i >= 10) {
          clearInterval(timer.value);
        }
        const lastSyncTimeObj = await groupChatApi.getLastSyncTime();
        if (lastSyncTimeObj.processStatus === "finished") {
          isSync.value = false;
          message.success("同步成功");
          syncTime.value = lastSyncTimeObj.lastSyncTime;
          await fetchPaginationData();
          clearInterval(timer.value);
        }
      }, 10000);
    }

    const columns = [
      {
        title: "客户群",
        dataIndex: "name",
        width: "20.5%",
      },
      {
        title: "群主",
        dataIndex: "ownerWorkWechatThirdAppUserId",
        slots: { customRender: "ownerWorkWechatThirdAppUserId" },
      },
      {
        title: "群人数",
        dataIndex: "totalAddCnt",
      },
      {
        title: "建群时间",
        dataIndex: "createdTime",
        slots: { customRender: "createdTime" },
      },
      {
        title: "操作",
        key: "action",
        width: "13%",
        slots: { customRender: "action" },
      },
    ];

    const {
      rows,
      pagination,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
    } = usePaginationQuery(router, searchForm, groupChatApi.search);

    const debounceSearch = _.debounce(onSearchSubmit, 500);

    initSyncGroup();
    getWxAgentConfig();

    return {
      searchForm,

      timeValue,
      handleSetectTime,

      isSync,
      syncTime,
      timer,
      handleSyncButton,

      columns,
      group: rows,
      pagination,
      dayjs,
      pullQueryParams,
      fetchPaginationData,
      onSearchSubmit,
      onPaginationChange,
      tableLoading,
      debounceSearch,
    };
  },

  beforeUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  },
});
</script>
<style  scoped>
</style>